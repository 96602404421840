.wrappers {
  display: flex;
  align-items: top;
  justify-content: space-between;
  background-color: #e3e3e3;
  height: 100vh;
}

.headers {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 23%;
}

.pages {
  width: 77%;
  padding: 10px;
}

.logo-wrap {
  background-color: #e3e3e3;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
}

.logo-wrap > img{
    position: absolute;
    z-index: 2;
    width: 100px;
}

/* .big_wrap{
    height: 100vh;
    padding: 10px;
} */

.box {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  background: #c8b9ec;
  z-index: 1;
}
